import React from "react";
import Page from "../components/page/page.js";

export const PrivacyPolicy = () => {
  return (
    <Page title="About">
      <div className="text-content">
        <h1>About</h1>
        <p>
          SG Land Transport is the best app on the market to consume and display
          information / data made available by the "LTA Datamall".
        </p>
        <ul>
          <li>100% free</li>
          <li>100% ad-free</li>
          <li>100% open-source</li>
        </ul>
        <h1>Support Us</h1>
        <ul>
          <li>
            <a
              href="https://twitter.com/intent/follow?screen_name=sgltapp"
              rel="noopener noreferrer"
              target="_blank"
              title="Tweet about SG Land Transport"
            >
              Follow us on Twitter
            </a>
          </li>
          <li>
            <a
              href="https://www.facebook.com/sglandtransportapp"
              rel="noopener noreferrer"
              target="_blank"
              title="Like and share our Facebook page"
            >
              Like and share our Facebook page
            </a>
          </li>
          <li>
            <a
              href="https://github.com/bytecrumbs/sglandtransport"
              rel="noopener noreferrer"
              target="_blank"
              title="Like and share our Facebook page"
            >
              Star our public GitHub repo
            </a>
          </li>
        </ul>
        <h1>Credits</h1>
        <ul>
          <li>
            Photo credit:{" "}
            <a
              href="https://500px.com/blackmagicx"
              target="_blank"
              rel="noopener noreferrer"
              title="Black Magic"
            >
              Black Magic
            </a>
          </li>
          <li>
            Illustration used in the app is inspired by{" "}
            <a
              href="https://dribbble.com/shots/1955446-WIP-Singapore"
              target="_blank"
              rel="noopener noreferrer"
              title="Ranganath Krishnamani"
            >
              Ranganath Krishnamani's work
            </a>
          </li>
          <li>
            SVG used for helicopter on nearby screen by{" "}
            <a
              href="https://www.flaticon.com/authors/freepik"
              target="_blank"
              rel="noopener noreferrer"
              title="Freepik"
            >
              Freepik
            </a>
          </li>
        </ul>
        <h1>Privacy policy</h1>
        <p>
          We built SG Land Transport as a Free app. This SERVICE is provided at
          no cost and is intended for use as is.
        </p>
        <p>
          This page is used to inform visitors regarding our policies with the
          collection, use, and disclosure of Personal Information if anyone
          decided to use our Service.
        </p>
        <p>
          If you choose to use our Service, then you agree to the collection and
          use of information in relation to this policy. The Personal
          Information that we collect is used for providing and improving the
          Service. We will not use or share your information with anyone except
          as described in this Privacy Policy.
        </p>
        <h1>Information Collection and Use</h1>
        <p>
          For a better experience, while using our Service, we may require you
          to provide us with certain personally identifiable information. The
          information that we request will be retained on your device and is not
          collected by us in any way.
        </p>
        <p>
          The app does use third party services that may collect information
          used to identify you.
        </p>
        <p>
          Link to privacy policy of third party service providers used by the
          app
        </p>
        <ul>
          <li>
            <a
              href="https://firebase.google.com/policies/analytics"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google Analytics for Firebase
            </a>
          </li>
          <li>
            <a
              href="https://firebase.google.com/support/privacy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Firebase Crashlytics
            </a>
          </li>
        </ul>
        <h1>Log Data</h1>
        <p>
          We want to inform you that whenever you use our Service, in a case of
          an error in the app we collect data and information (through third
          party products) on your phone called Log Data. This Log Data may
          include information such as your device Internet Protocol (“IP”)
          address, device name, operating system version, the configuration of
          the app when utilizing my Service, the time and date of your use of
          the Service, and other statistics.
        </p>
        <p>
          Cookies are files with a small amount of data that are commonly used
          as anonymous unique identifiers. These are sent to your browser from
          the websites that you visit and are stored on your device's internal
          memory.
        </p>
        <p>
          This Service does not use these “cookies” explicitly. However, the app
          may use third party code and libraries that use “cookies” to collect
          information and improve their services. You have the option to either
          accept or refuse these cookies and know when a cookie is being sent to
          your device. If you choose to refuse our cookies, you may not be able
          to use some portions of this Service.
        </p>
        <h1>Service Providers</h1>
        <p>
          We may employ third-party companies and individuals due to the
          following reasons:
        </p>
        <ul>
          <li>To facilitate our Service;</li>
          <li>To provide the Service on our behalf;</li>
          <li>To perform Service-related services; or</li>
          <li>To assist us in analyzing how our Service is used.</li>
        </ul>
        <p>
          We want to inform users of this Service that these third parties have
          access to your Personal Information. The reason is to perform the
          tasks assigned to them on our behalf. However, they are obligated not
          to disclose or use the information for any other purpose.
        </p>
        <h1>Security</h1>
        <p>
          We value your trust in providing us your Personal Information, thus we
          are striving to use commercially acceptable means of protecting it.
          But remember that no method of transmission over the internet, or
          method of electronic storage is 100% secure and reliable, and we
          cannot guarantee its absolute security.
        </p>
        <h1>Links to Other Sites</h1>
        <p>
          This Service may contain links to other sites. If you click on a
          third-party link, you will be directed to that site. Note that these
          external sites are not operated by us. Therefore, we strongly advise
          you to review the Privacy Policy of these websites. We have no control
          over and assume no responsibility for the content, privacy policies,
          or practices of any third-party sites or services.
        </p>
        <h1>Children’s Privacy</h1>
        <p>
          These Services do not address anyone under the age of 13. We do not
          knowingly collect personally identifiable information from children
          under 13. In the case we discover that a child under 13 has provided
          us with personal information, we immediately delete this from our
          servers. If you are a parent or guardian and you are aware that your
          child has provided us with personal information, please contact us so
          that we will be able to do necessary actions.
        </p>
        <h1>Changes to this Privacy Policy</h1>
        <p>
          We may update our Privacy Policy from time to time. Thus, you are
          advised to review this page periodically for any changes. We will
          notify you of any changes by posting the new Privacy Policy on this
          page.
        </p>
        <p>This policy is effective as of 2020-05-08</p>
        <h1>Contact Us</h1>
        <p>
          If you have any questions or suggestions about our Privacy Policy, do
          not hesitate to contact us at{" "}
          <a href="mailto:sglandtransportapp@gmail.com">
            sglandtransportapp@gmail.com.
          </a>
        </p>
      </div>
    </Page>
  );
};

export default PrivacyPolicy;
